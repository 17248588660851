import React from "react";
import {
	Box,
	Grid,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {convertNewlinesToBreaks} from "../../../../../helper/convertor";

const UserInfo = (props) => {
	const {
		client,

		vin,
		mileage,
		reasonForContacting
	} = props;
	const classes = useStyles();

	return (
		<>
			<Box mb={1}>
				<Typography variant="h3" textAlign="left">Информация о пользователе</Typography>
			</Box>
			<Grid className={classes.root} container spacing={1}>
				<Grid item>
					Фамилия - <b>{ client.lastName }</b>;
				</Grid>
				<Grid item>
					Имя - <b>{ client.firstName }</b>;
				</Grid>
				<Grid item>
					Номер телефона - <b>{ client.phone }</b>;
				</Grid>
				<Grid item xs={12}>
					VIN автомобиля - <b>{ vin || 'Неизвестно' }</b>;
				</Grid>
				<Grid item xs={12}>
					Пробег автомобиля - <b>{ mileage || 'Неизвестно' }</b>;
				</Grid>
				<Grid item xs={12}>
					Причина обращения - <b dangerouslySetInnerHTML={{__html: convertNewlinesToBreaks(String(reasonForContacting || 'Неизвестно'))}}/>
				</Grid>
			</Grid>
		</>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		"& b": {
			fontWeight: "500",
			textDecoration: "underline"
		}
	}
}));

export default React.memo(UserInfo)
