import React from "react";
import {
	Box
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Header
} from "./components";
import {compose} from "recompose";
import {connect} from "react-redux";

class Client extends React.PureComponent {
	render () {
		const {
			is_auth,
			classes
		} = this.props;

		return (
			<Box className={classes.root}>

				<Header
					isAuth={is_auth}
				/>

				<Box className={classes.body}>
					{ this.props.children }
				</Box>

			</Box>
		)
	}
}

const styles = {
	root: {
		display: "flex",
		flexDirection: "column",

		height: "100%",
		overflow: "hidden",
		backgroundColor: "#F2F2F6"
	},
	body: {
		padding: "12px 24px",
		boxSizing: "border-box",
		height: "calc(100% - 60px)",
		overflow: "auto",

		"@media(max-width: 1199px)": {
			padding: 12
		}
	},
}
Client = withStyles(styles)(Client)

export default compose(
	connect(
		state => ({
			is_auth: state.global?.is_auth || false
		}),
		dispatch => ({}),
	),
)(Client)
