import agent from "../agent/agent";
import {
	Notification,
	notificationTypes
} from "../common/Notification";

const SET_USER = "app/SET_USER"
const SET_AUTH = 'app/SET_AUTH';
const SET_CHOSEN_APPLICATION_ID = "app/SET_CHOSEN_APPLICATION_ID";

const initialState = {
	user: {},
	is_auth: false,
	chosenApplicationId: null
};

export function setUser () {
	return async function (dispatch) {
		const accessToken = localStorage.getItem("accessToken");
		if (!accessToken) {
			return null
		}

		const user = await agent.get(`/user/me`).then((res) => {
			return res.data
		}).catch(() => {
			return null
		});
		if (!user) {
			localStorage.removeItem("accessToken");

			return null
		}

		if (user.role !== 'admin') {
			localStorage.removeItem("accessToken");
			Notification({
				type: notificationTypes.error,
				message: "Вы не являетесь администратором",
			});

			return
		}

		dispatch({
			type: SET_USER,
			user: {
				isActive: true,
				role: user.role,
				firstName: user.firstName,
				lastName: user.lastName,
			},
		});
		dispatch({
			type: SET_AUTH,
			is_auth: true,
		});
	}
}
export function logoutUser () {
	return async function (dispatch) {
		localStorage.removeItem("accessToken");

		dispatch({
			type: SET_USER,
			user: {},
		});
		dispatch({
			type: SET_AUTH,
			is_auth: false,
		});
	}
}

export function setChosenApplicationId(id) {
	return function (dispatch) {
		dispatch({
			chosenApplicationId: id,
			type: SET_CHOSEN_APPLICATION_ID,
		});
	};
}

export default function AppState(state = initialState, action = {}) {
	switch (action.type) {
		case SET_USER: {
			return {
				...state,
				user: action.user
			};
		}

		case SET_AUTH: {
			return {
				...state,
				is_auth: action.is_auth,
			}
		}

		case SET_CHOSEN_APPLICATION_ID: {
			return {
				...state,
				chosenApplicationId: action.chosenApplicationId,
			};
		}

		default:
			return state;
	}
}


